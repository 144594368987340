export const words = [
    'ACEITE MINERAL USP - SECTOR: INDUSTRIAL / COSMÉTICOS',
    'ÁCIDO ACÉTICO USP - SECTOR: INDUSTRIAL / LIMPIEZA / ALIMENTOS',
    "ÁCIDO ASCÓRBICO - SECTOR: ALIMENTOS",
    "ÁCIDO CÍTRICO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "ÁCIDO ESTEÁRICO (TRIPLE PRENSADO) - SECTOR: LIMPIEZA/COSMÉTICOS",
    "ÁCIDO FOSFÓRICO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "ÁCIDO FUMÁRICO - SECTOR: ALIMENTOS",
    "ÁCIDO SULFÓNICO (96%) - SECTOR: INDUSTRIAL / LIMPIEZA",
    "ALBÚMINA DE HUEVO - SECTOR: ALIMENTOS",
    "ALMIDONES - SECTOR: ALIMENTOS",
    "ALCOHOL ISOPROPÍLICO (99%) - SECTOR: INDUSTRIAL / LIMPIEZA",
    "ALCOHOL CETÍLICO - SECTOR: LIMPIEZA / COSMÉTICOS",
    "ALCOHOL ETÍLICO - SECTOR: LIMPIEZA",
    "BENZOATO DE SODIO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "BICARBONATO DE SODIO - SECTOR: ALIMENTOS",
    "BUTILGLICOL - SECTOR: INDUSTRIAL / LIMPIEZA",
    "CARBOMER - SECTOR: COSMÉTICOS",
    "CARBOXIMETIL CELULOSA - C.M.C (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "CARRAGENATO - SECTOR: ALIMENTOS",
    "CERA EN ESCAMAS - SECTOR: LIMPIEZA",
    "CITRATO DE SODIO - SECTOR: ALIMENTOS",
    "CLORURO DE BENZALCONIO (80%) DODIGEN - SECTOR: LIMPIEZA",
    "CLORURO DE CALCIO - SECTOR: ALIMENTOS",
    "CLORURO DE CETRIMONIO (30%) GENAMIN - SECTOR: COSMÉTICOS",
    "CLORURO DE METILENO - SECTOR: INDUSTRIAL",
    "COCAMIDA DEA - SECTOR: COSMÉTICOS",
    "COCAMIDOPROPIL BETAINA - SECTOR: COSMÉTICOS",
    "COLORANTES LÍQUIDOS (SOLUBLES EN AGUA) - SECTOR: LIMPIEZA / COSMÉTICOS",
    "DEXTROSA MONOHIDRATADA (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "EDTA 2NA DISÓDICO - SECTOR: ALIMENTOS",
    "EDTA TETRASÓDICO - SECTOR: ALIMENTOS",
    "ERITORBATO DE SODIO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "FORMOL (37%) - SECTOR: LIMPIEZA",
    "FOSFATO TRICÁLCICO - SECTOR: ALIMENTOS",
    "GELATINA 250 Y 265 BLOOM - SECTOR: ALIMENTOS",
    "GENAPOL EN PASTA - SECTOR: LIMPIEZA / COSMÉTICOS",
    "GLICERINA VEGETAL USP (99.7%) - SECTOR: LIMPIEZA / COSMÉTICOS",
    "GLUTAMATO DE SODIO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "GOMA GUAR 5500 (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "GOMA XANTHAN MALLA 80 Y 200 (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "HIDRÓXIDO DE POTASIO (POTASA CÁUSTICA) - SECTOR: INDUSTRIAL",
    "LACTATO DE SODIO 60% - SECTOR: ALIMENTOS",
    "MALTODEXTRINA - SECTOR: ALIMENTOS",
    "METABISULFITO DE SODIO - SECTOR: ALIMENTOS",
    "METASILICATO DE SODIO PENTAHIDRATADO - SECTOR: LIMPIEZA",
    "MEZCLA DE L-LACTATO DE SODIO Y ACETATO DE SODIO - SECTOR: ALIMENTOS",
    "NIPACIDEXIOLINA-5 - SECTOR: LIMPIEZA / COSMÉTICOS",
    "NONIL FENOL 10 MOLES - SECTOR: LIMPIEZA / COSMÉTICOS",
    "PERÓXIDO DE HIDRÓGENO - SECTOR: LIMPIEZA",
    "POLIDEXTROSA - SECTOR: ALIMENTOS",
    "PROPIANATO DE CALCIO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "PROPILENGLICOL USP - SECTOR: COSMÉTICOS / ALIMENTOS",
    "PROTEÍNA AISLADA DE SOYA - SECTOR: ALIMENTOS",
    "PROTEÍNA HIDROLIZADA DE SOYA - SECTOR: ALIMENTOS",
    "SODA CÁUSTICA - SECTOR: INDUSTRIAL / LIMPIEZA",
    "SORBATO DE POTASIO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "SULFATO DE ALUMINIO (GRANULADO) - SECTOR: INDUSTRIAL",
    "SULFATO DE ALUMINIO LÍQUIDO (LIBRE DE HIERRO) - SECTOR: INDUSTRIAL",
    "TEXAPON N-70 (SLES 70%) - SECTOR: LIMPIEZA / COSMÉTICOS",
    "TRIPOLIFOSFATO DE SODIO (GRADO ALIMENTICIO) - SECTOR: ALIMENTOS",
    "TRIPOLIFOSFATO DE SODIO (GRADO TÉCNICO) - SECTOR: LIMPIEZA",
    "XILENO - SECTOR: INDUSTRIAL",

];