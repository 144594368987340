import React from 'react';
import IndustryDirect from "../components/IndustryDirect";
import Contacto from "../components/ContactoEnviar";
import Footer from "../components/footer";
import Industry from "../components/Industrial";
import Ws from "../components/wsBtn";
import { useEffect } from 'react';
import { useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineProduct} from "react-icons/ai";
import { MdSpaceDashboard, MdOutlineWebAsset, MdOutlineArrowDropDownCircle } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { RiContactsBook3Line } from "react-icons/ri";
import noumaIco from "../FavIcon/favicon.ico";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import nouma from "../Imagenes/quimicaNOUMA.png";
import { useTranslation } from "react-i18next";
import { words } from '../components/data';
import { BsSearch } from 'react-icons/bs';

export default function Home() {

  const [inputValue, setInputValue] = useState('');
  const handleInput = (e) => {
    const newValue = e.target.value.toUpperCase();
    setInputValue(newValue);
  };
    
  const [activeSearch, setActiveSearch] = useState([])

  const handleSearch = (e) => {
      if(e.target.value === ''){
          setActiveSearch([])
          return false
      }
      setActiveSearch(words.filter(w => w.includes(e.target.value.toUpperCase())).slice(0,40))
  }

  const [t] = useTranslation("global");
  const [open, setOpen] = useState(true);
  const [submenuopen, setSubmenuOpen] = useState(true);
  const [submenuopen2, setSubmenuOpen2] = useState(false);
  const Menus = [
    { title: t("sidebar.home"), icon: <FiHome />, href: "../Home"},
    { title: t("sidebar.products"), 
      icon: <AiOutlineProduct />,
      href: "../Products",
      submenu: true,
      subemnuItems: [
        {title: t("productsMenu.industry"),
        href: "../Products/Industry"
        },
        {title: t("productsMenu.clean"),
          href: "../Products/Cleaning"
        },
        {title: t("productsMenu.cosmetics"),
        href: "../Products/Cosmetics"},
        {title: t("productsMenu.food"),
        href: "../Products/Food"},
      ],
    },
    { title: t("sidebar.pages"), 
      icon: <MdOutlineWebAsset onClick={() => setOpen(!open) & setSubmenuOpen2(!submenuopen2)} />,
      submenu2: true,
      subemnuItems2: [
        {title: "Grupo Nouma", href: "https://www.gruponouma.com"},
        {title: "Nouma Trading & Logistics", href: "https://noumatrading.com.ve"},
        {title: "Vencatalyst", href: "https://vencatalyst.com.ve"},
      ],
    },
    { title: t("sidebar.contact"), icon: <RiContactsBook3Line />, href: "../Contact"},
  ];
  useEffect(() => {
    document.title = 'Industrial - Química Nouma';
  }, []);
    return(
    <section className="sticky top-0 flex-row font-Pthin">
        <div className="flex sticky top-0 z-20">
            <div className={`bg-[#27405E] h-screen ${open ? "md:w-72 w-60 md:opacity-100 opacity-95" : "md:w-20 w-0"} duration-500 absolute z-10`}>
                <BsArrowLeftShort className={`bg-white hover:bg-[#00ADB5] text-[#00DFA2] hover:text-black text-3xl rounded-full absolute -right-10 top-9 border border-[#00DFA2] hover:border-black cursor-pointer duration-200 -mt-2 ${!open && "rotate-180"}`} onClick={() => setOpen(!open) & setSubmenuOpen2(!submenuopen2)}/>

                <div className={` bg-white ${!open ? "w-[5rem] items-center md:inline-flex hidden h-20 px-4": "md:px-8 pl-8"} ${!open ? " py-4 pb-0 duration-500": " flex justify-center"}`}>
                  {!open ? <motion.img id="NoumaIco" src={noumaIco} className={`size-10 cursor-pointer block float-left mr-2 duration-500`}
                   alt="Nouma Icono"
                   /> : <motion.img alt="Nouma Logo" src={nouma} className='duration-500 w-60 h-auto max-md:-ml-5'
                   initial={{ x: -50, opacity: 1 }}
                   animate={{ x: 0, opacity: 1}}
                   transition={{
                     delay: 0,
                     type: 'spring',
                     stiffness: 200,
                     damping: 20,
                     mass: 0.5
                   }}
                  />}
                </div>
                <div className=" p-5 pt-1">
                   <div className={` flex item-center rounded-md bg-slate-500 mt-6 ${open ? 'px-2.5': 'px-2'} ${!open && "max-md:hidden"}`}>
                      <BsSearch className='text-white text-lg block float-left cursor-pointer mx-1 my-2' onClick={() => setOpen(!open)}/>
                      <input type="search" value={inputValue} onInput={handleInput} placeholder='Buscar' className={`text-base bg-transparent w-full text-white focus:outline-none ${!open && "hidden"}`} onChange={(e) => handleSearch(e)} />
                      {
            activeSearch.length > 0 && (
                <div className={`absolute md:left-[28rem] overflow-scroll left-1/2 max-md:top-52 z-50 p-4 bg-slate-500 text-white md:w-full w-48 md:h-[40rem] h-80 rounded-xl -translate-x-1/2 flex flex-col gap-2 ${!open && "hidden"}`}>
                    {
                        activeSearch.map(s => (
                            <span>{s}</span>
                        ))
                    }
                </div>
            )
        }
                   </div>
                <ul className={`pt-2   ${!open ? "max-md:hidden": ""}`}>
                {Menus.map((menu, index) =>(
                    <>
                        <li key={index} className={`text-white text-xl flex items-center gap-x-4 cursor-pointer p-2 duration-100 hover:bg-[#00ADB5] hover:text-black ${menu.spacing ? "mt-9" : "mt-2"} mt-2  border-b-2 border-black`} >
                            <a className="text-2xl block float-left" href={menu.href}>
                            {menu.icon ? menu.icon : <MdSpaceDashboard/>}
                            </a>
                            <a href={menu.href} className={`text-base font-medium flex-1 duration-500 ${!open && "hidden"}`}>{menu.title}</a>
                            {menu.submenu && open && (
                            <MdOutlineArrowDropDownCircle className={`text-white text-2xl rounded-full border duration-200 border-white hover:border-black cursor-pointer hover:bg-[#00ADB5] hover:text-black ${submenuopen && "rotate-180"}`} onClick={() => setSubmenuOpen (!submenuopen)}/>
                            )}
                            {menu.submenu2 && open && (
                            <MdOutlineArrowDropDownCircle className={`text-white text-2xl rounded-full border border-white hover:border-black cursor-pointer hover:bg-[#00ADB5] hover:text-black ${submenuopen2 && "rotate-180"}`} onClick={() => setSubmenuOpen2 (!submenuopen2)}/>
                            )}
                        </li>

                        {menu.submenu && submenuopen && open && (
                        <motion.div
                        className="box"
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.8,
                          ease: [0, 0.71, 0.2, 1.01]
                        }}
                        >
                        <ul>
                            {menu.subemnuItems.map((subemnuItem, index) =>(
                                <motion.li 
                                whileHover={{ scale: [null, 1.1, 1.1] }}
                                key={index} className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 px-5 hover:bg-[#00ADB5] hover:text-black rounded-md ${menu.spacing ? "mt-5" : "mt-2"} mt-2`}>
                                    <a href={subemnuItem.href} className={`flex-1 duration-500 ${!open && "hidden"}`}>{subemnuItem.title}</a>
                                </motion.li>
                            ))}
                        </ul>  
                        </motion.div>
                        )}
                    {menu.submenu2 && submenuopen2 && open && (
                      <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        ease: [0, 0.71, 0.2, 1.01]
                      }}
                      >
                        <ul>
                            {menu.subemnuItems2.map((subemnuItem2, index) =>(
                                <motion.li 
                                whileHover={{ scale: [null, 1.1, 1.1] }}
                                 key={index} href={subemnuItem2.href} className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 px-5 hover:bg-[#00ADB5] hover:text-black rounded-md ${menu.spacing ? "mt-5" : "mt-2"} mt-2`}>
                                    <a href={subemnuItem2.href} target="_blank" rel="noopener noreferrer" className={`flex-1 duration-500 ${!open && "hidden"}`}>{subemnuItem2.title}</a>
                                </motion.li>
                            ))}
                        </ul>
                        </motion.div>
                        
                    )}
                    </>

                ))}
                
                </ul>
                
                </div>

            </div>
            <div className={`bg-[#27405E] ${open ? "md:h-[9.4rem] h-[8.7rem]" : "h-20"} flex w-screen duration-500 items-center justify-end z-0`}>
            <Navbar />
            </div>
        </div>
      <>
      <div className='font-Pthin'>
          <div className='z-20 sticky top-0'>
            <Ws/>
          </div>
          <div className={`flex z-0 duration-500  ${open ? "md:ml-72" : "md:ml-20"}`}>
            <IndustryDirect></IndustryDirect>
          </div>
          <div className={`flex z-0 duration-500 ${open ? "md:ml-72" : "md:ml-20"}`}> 
            <Industry></Industry>
          </div>
          <div className={`flex z-0 duration-500 ${open ? "md:ml-72" : "md:ml-20"}`}> 
            <Contacto></Contacto>
          </div>
          <div className={`flex z-0 duration-500 ${open ? "md:ml-72" : "md:ml-20"}`}>
            <Footer></Footer>
          </div>
    </div>
    </>
  </section>


  
  );
}